<template>
	<section class="content redesign">
		<div class="modal show" tabindex="-1" role="dialog" ref="formForgotPass" data-backdrop="static">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content" style="padding:28px;border-radius: 6px;">
					<div class="modal-header nopadding">
						<span class="font-20-bold  mb-3" style="cursor: pointer;">Lupa Kata Sandi</span>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="backPin">
							<span class="font-30-medium" aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body nopadding" style="margin: 16px 0 0;">
						<form role="form" id="form_user" @submit.prevent="submitForm()"  v-if="!showModSucces">
							<div class="card-body" style="padding:20px 0">
								<div class="row">
									<div class="form-group col-md-12" style="display: flex;flex-direction: column; gap: 8px;">
										<span class="font-14-medium text-neutral-dark-03" >Masukkan Email</span>
										<input type="email" value="" class="form-control" v-model="email" placeholder="Alamat Email" />
										<span class="font-12-reguler font-weight text-right text-red">{{errMessage}}</span>
									</div>
								</div>
							</div>
							<!-- /.box-body -->
							<div class="card-footer form-actions d-flex justify-content-end nopadding" style="gap:8px">
								<button id="tb_close" class="btn-secondary-white" @click="backPin">
									<span class="font-16-bold">Batal</span> 
								</button>
								<button type="submit" id="tb_save" class="btn-primary-orange" style="gap: 8px; display: flex; flex-direction: row;">
									<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
										aria-hidden="true"></span> <span class="font-16-bold">Reset Kata Sandi</span> 
								</button>
							</div>
						</form>
						<div v-if="showModSucces" style="gap:32px; display: flex; flex-direction: column;" class="text-center">
							<span class="font-18-semi_bold">{{ message }}</span>
							<div class="card-footer form-actions d-flex justify-content-end nopadding">
								<button type="submit" id="tb_close" class="btn-primary-orange" @click="backPin">
									<span class="font-16-bold">Tutup</span> 
								</button>
							</div>
						</div>
						
					</div>
					<div class="modal-footer" hidden></div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import $ from "jquery";

export default {
	props: {
		showForgotPass: Number
	},
	data() {
		return {
			showModSucces: false,
			email: "",
			loading:false,
			message:"",
			errMessage:""
		};
	},
	components: {},
	created() {},
	methods: {
		backPin() {
			$(this.$refs.formForgotPass).modal("hide");
		},
		submitForm: function (e)  {
			if (this.email) {
				var data = "email=" + this.email;
				this.loading = true;
				this.failed = false;
				fetch(window.$apiUrl + "/login/forgot", {
						method: "POST",
						headers: {
							"Content-Type": "application/x-www-form-urlencoded"
						},
						body: data
					})
					.then(res => {
						this.loading = false;
						if (res.status === 201) {} else if (res.status === 400) {
							//let errorResponse = await res.json();
							//this.errors.push(errorResponse.error);
						}
						return res.json();
					})
					.then(js => {
						if (js.status) {
							this.showModSucces = true;
							this.email = "";
							this.message =  js.message;
						} else {
							this.errMessage = js.message;
							this.resetClass = "alert alert-danger";
						}
						this.resetAction = true;
					});
			}else{
				this.errMessage = "Email wajib di isi";
			};
			e.preventDefault();
		},

	},
	mounted() {},
	watch: {
		showForgotPass: function (old, val) {
			if(val>0){
				$(this.$refs.formForgotPass).modal("show");
			}else{
				$(this.$refs.formForgotPass).modal("hide");
			}
		},
		messagePin: function (old, val) {},
	}
};
</script>
<style scoped>
	div.vue-pincode-input-wrapper {
		display: flex;
		justify-content: center;
	}

	input.vue-pincode-input {
		border-bottom: 1px solid orange;
		box-shadow: none !important;
	}
</style>